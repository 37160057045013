<template>
  <div :style="{ padding: '0 0 32px 32px' }">
    <h4 :style="{ marginBottom: '20px' }">{{ title }}</h4>
    <v-chart
      height="256"
      :data="data"
      :scale="scale"
      :forceFit="true"
      :padding="['10', '10', '40', '50']">
      <v-tooltip />
      <v-axis :label="label"/>
<!--      <v-legend />-->
<!--      <v-bar position="x*y"/>-->
      <v-smooth-line position="x*y" shape="smooth"/>
      <v-point position="x*y" :size="4" :style="style" :shape="'circle'" />
    </v-chart>
  </div>
</template>

<script>
export default {
  name: 'Bar',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    scale: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const label = {
      density: 0,
      rotate: 30
    }
    return {
      settings: {
        labelMap: {
          'y': '地区',
          'x': '拓展数'
        }
      },
      label,
      style: { stroke: '#fff', lineWidth: 1 }
    }
  }
}
</script>
